/**
    Basic styles
 */
html,
body {
    height: 100%;
    margin: 0;
    background: @site-bg;
}

body {
    font-family: @font-base;
}

h1 {
    margin-bottom: 1rem;
}

h2,
h3,
h4,
h5,
h6 {
    margin: 1rem 0 0.5rem;
}

ul {
    padding: 0;
    margin: 0;
}

a {
    color: @link-color;
    //word-break: break-all;

    .ant-tooltip & {
        color: white;
    }

    &.active {
        color: #1890ff;
    }
}

hr {
    opacity: 0.2;
}

label {
    user-select: none;
}

ul {
    list-style: none;
}

strong {
    font-weight: 600;
}

dd {
    margin: 0;
}

kbd {
    border: 1px solid rgba(#ffb400, 0.75);
    padding: 0 4px;
    border-radius: @border-radius-base;
    color: #ffb400;
    white-space: nowrap;
}

:focus {
    outline: none;
}

/**
    Browser styles
 */

// Search appearance (clear button)
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: none;
}

// Change Autocomplete styles in Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: @autocomplete-color !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

hr {
    border: none;
    border-top: 1px solid darken(@border-color-alt, 20%);

    &.double {
        padding: 1px 0;
        border-bottom: 1px solid darken(@border-color-alt, 20%);
    }
}

small {
    font-size: 0.85em;
}

#root {
    height: 100%;
}
