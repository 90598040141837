.ant-transfer-list {
    background: rgba(white, 0.5);
}

.ant-timeline {
    padding-left: 3px;

    .ant-timeline-item-label {
        margin-left: 25px;
        position: relative;
        top: -6px;
    }

    .ant-timeline-item-head {
        background-color: @site-bg;
    }

    .ant-timeline-item-tail {
        border-left: 1px solid @border-color-alt;
    }

    .ant-timeline-item {
        &:last-child {
            .ant-timeline-item-tail {
                display: none;
            }
        }
    }
}

.ant-tooltip-inner {
    max-height: 200px;
    overflow-y: auto;
}

.ant-empty {
    .ant-empty-image {
        height: fit-content;
    }

    .ant-empty-description {
        color: @muted-color;
        opacity: 0.75;
        font-size: 0.7rem;
        font-weight: 600;
    }

    svg {
        height: 60px;
    }
}
