/**
    Example of use:
    @media @smMin {
      footer {
        width: 940px;
      }
    }

    NOTE: also check _cssVariables.less
 */
/**

    Don't forget sync, before commit changes!

    Synced with:
        /src/styles/global/_cssVariables.less
        /src/config/colors.ts


    Note:
        preferred use antd color variant 5 -> @green-5

 */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    transform: translate3d(0, 15px, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    transform: translateX(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-500 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-600.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-800.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-800.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
:root {
  --gray-color: #acacbc;
  --red-color: #ff4d4f;
  --purple-color: #9254de;
  --black-color: #1f2d37;
  --white-color: #fff;
  --user-color: #7fd778;
  --customer-color: #d768ab;
  --writer-color: #32b4d0;
  --primary-color: #0078f0;
  --stripped-color: #f9fbfd;
  --success-color: #78cd62;
  --danger-color: #df6060;
  --warning-color: #ffc53d;
  --error-color: #df6060;
  --unable-color: #bec7da;
  --info-color: #40a9ff;
  --turquoise-color: #006ba7;
  --muted-color: #848b92;
  --muted-text-color: #75a3d1;
  --draft-color: #ffc53d;
  --paused-color: #848b92;
  --active-color: #78cd62;
  --resolved-color: #78cd62;
  --pending-color: #b37feb;
  --inwork-color: #40a9ff;
  --removed-color: #df6060;
  --aborted-color: #df6060;
  --declined-color: #df6060;
  --available-color: #40a9ff;
  --disabled-color: #c9d2da;
  --border-color: #e6eff6;
  --link-color: #006bd7;
  --text-color: #1f2d37;
  --heading-color: #1f2d37;
  --header-height: 60px;
  --xxs: 360;
  --xs: 576;
  --sm: 768;
  --md: 992;
  --lg: 1200;
  --xl: 1400;
  --xxl: 1600;
}
.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.85;
}
.discarded {
  opacity: 0.5;
}
.discarded:hover {
  opacity: 1;
}
.clickable {
  cursor: pointer;
}
.tiny-shadows {
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
  transition: box-shadow 0.2s, border-collor 0.2s;
}
.default-shadows {
  box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}
.card {
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
  transition: box-shadow 0.2s, border-collor 0.2s;
  padding: 1rem;
  border-radius: 8px;
  background: white;
  border: 1px solid #e6eff6;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.justify-start {
  justify-content: flex-start;
}
.multiplier {
  font-weight: 600;
  font-style: normal;
  font-size: 0.7rem;
  border-radius: 4px;
  opacity: 0.5;
  padding: 0 1px;
  width: 35px;
  justify-content: center;
  align-self: center;
}
.meta {
  background: rgba(255, 0, 0, 0.1);
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
}
.opacity-5 {
  opacity: 0.5;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.ant-card {
  border: 1px solid #e6eff6;
  display: flex;
  flex-flow: column;
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
  transition: box-shadow 0.2s, border-collor 0.2s;
}
.ant-card .ant-card-body {
  flex: 1;
}
.ant-card .ant-card-head {
  background: #fff;
  border-bottom-color: #e6eff6;
}
.ant-card .ant-card-head .ant-card-head-title {
  overflow: visible;
}
.ant-card .ant-card-actions {
  background: #f9fbfd;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  display: flex;
}
.ant-card .ant-card-actions li {
  flex: 1;
  width: unset !important;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-card.ant-card-small > .ant-card-head .ant-card-head-title {
  padding: 6px 0;
}
.ant-btn {
  font-weight: 600;
}
.ant-btn.ant-btn-square {
  padding: 0 10px;
}
div.ant-table {
  border-radius: 5px;
  background: #fff;
  border: 1px solid #dfebf4;
  overflow: auto;
}
div.ant-table .ant-table {
  margin: 0 !important;
}
.ant-table-body {
  overflow-x: auto !important;
}
.ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  opacity: 0.5;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  opacity: 0.5;
}
form.default-form .ant-input-number,
form.default-form .ant-input,
form.default-form .ant-select {
  min-width: unset;
  width: 100%;
  max-width: 100%;
}
form.form-condensed .ant-row,
form.form-condensed .ant-form-item {
  margin-bottom: 5px;
}
.ant-form .ant-row {
  max-width: 100%;
}
.ant-form-item-extra {
  color: #7e96ad;
  font-size: 12px;
  padding: 3px 3px 0;
}
.ant-form-item-explain {
  font-size: 12px;
  padding-top: 1px;
}
.ant-tabs-tabpane {
  padding: 2px 2px 2px 4px;
}
.ant-tag {
  font-weight: 600;
}
.ant-select:not([style*="width"]) {
  min-width: 160px;
}
.ant-select-multiple .ant-select-selection-item-content > div + div {
  display: none;
}
.ant-select-multiple .ant-select-selection-item {
  background: rgba(0, 0, 0, 0.01);
  border-color: rgba(223, 235, 244, 0.2);
  border-radius: 6px;
}
.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content > div {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.ant-select-dropdown {
  box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}
.ant-transfer-list {
  background: rgba(255, 255, 255, 0.5);
}
.ant-timeline {
  padding-left: 3px;
}
.ant-timeline .ant-timeline-item-label {
  margin-left: 25px;
  position: relative;
  top: -6px;
}
.ant-timeline .ant-timeline-item-head {
  background-color: #f5f8fb;
}
.ant-timeline .ant-timeline-item-tail {
  border-left: 1px solid #e6eff6;
}
.ant-timeline .ant-timeline-item:last-child .ant-timeline-item-tail {
  display: none;
}
.ant-tooltip-inner {
  max-height: 200px;
  overflow-y: auto;
}
.ant-empty .ant-empty-image {
  height: fit-content;
}
.ant-empty .ant-empty-description {
  color: #848b92;
  opacity: 0.75;
  font-size: 0.7rem;
  font-weight: 600;
}
.ant-empty svg {
  height: 60px;
}
/**
    Basic styles
 */
html,
body {
  height: 100%;
  margin: 0;
  background: #f5f8fb;
}
body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
h1 {
  margin-bottom: 1rem;
}
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 0.5rem;
}
ul {
  padding: 0;
  margin: 0;
}
a {
  color: #006bd7;
}
.ant-tooltip a {
  color: white;
}
a.active {
  color: #1890ff;
}
hr {
  opacity: 0.2;
}
label {
  user-select: none;
}
ul {
  list-style: none;
}
strong {
  font-weight: 600;
}
dd {
  margin: 0;
}
kbd {
  border: 1px solid rgba(255, 180, 0, 0.75);
  padding: 0 4px;
  border-radius: 8px;
  color: #ffb400;
  white-space: nowrap;
}
:focus {
  outline: none;
}
/**
    Browser styles
 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #1f2d37 !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
hr {
  border: none;
  border-top: 1px solid #9bbfdb;
}
hr.double {
  padding: 1px 0;
  border-bottom: 1px solid #9bbfdb;
}
small {
  font-size: 0.85em;
}
#root {
  height: 100%;
}
.option-description {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  justify-content: space-between;
  font-size: 0.8rem;
}
.option-description div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  gap: 7px;
}
.option-description dt {
  color: rgba(31, 45, 55, 0.5);
}
.ant-input-textarea-show-count::after {
  font-size: 11px;
  font-weight: 600;
  color: rgba(31, 45, 55, 0.5);
}
.grid-cards {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.grid-cards.grid-large {
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}
.grid-cards.grid-normal {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.grid-cards.grid-small {
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}
.grid-cards.grid-xs {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.flex-grid {
  display: flex;
  gap: 20px;
  grid-gap: 20px;
}
.spin-fast {
  animation: fa-spin 0.3s linear infinite;
}
.ant-upload.ant-upload-drag {
  background: #f9fbfd;
}
.responsive-select-option .ant-select-item-option-content {
  white-space: normal;
  word-break: break-word;
}
.ant-form-item-control-input .ant-select-item-option-content {
  white-space: nowrap;
  word-break: normal;
}
.fa-spinner-third {
  animation: fa-spin 0.3s linear infinite;
}
.ant-modal .ant-modal-close-x {
  width: 46px;
  height: 46px;
  line-height: 46px;
}
.ant-modal .ant-modal-header {
  padding: 12px 15px;
}
.muted-text {
  color: #848b92;
}
