.fixedWidth(@w) {
    min-width: @w;
    max-width: @w;
}

.overlay(@pos: 0, @type: absolute) {
    position: @type;
    left: @pos;
    right: @pos;
    bottom: @pos;
    top: @pos;
}

.flexCenter(@gap: 5px, @justify: center, @align: center) {
    display: flex;
    align-items: @align;
    justify-content: @justify;
    gap: @gap;
}

.textOverflow() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lineClamp(@lines: 3) {
    display: -webkit-box;
    -webkit-line-clamp: @lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.addButtonVariant(@background-color, @text-color: white) {
    &,
    &:hover,
    &:focus {
        box-shadow: 0 2px 5px rgba(@background-color, 0.4);
        border: none;
        background: @background-color;
        color: @text-color;
    }

    // Status
    &:hover,
    &:focus {
        background: lighten(@background-color, 5%);
        box-shadow: 0 2px 5px rgba(@background-color, 0.4);
        color: @text-color;
    }
}

.addButtonOutlined(@main-color, @text-color: @main-color) {
    &,
    &:hover,
    &:focus {
        box-shadow: 0 2px 5px rgba(@main-color, 0.2);
        background: white;
        border: 1px solid @main-color;
        color: @text-color;
    }

    // Status
    &:hover,
    &:focus {
        opacity: 0.8;
    }
}

.invisible(@opacity:0.65) {
    opacity: @opacity;
    .addTransition;

    &:hover {
        opacity: 1;
    }
}

.tagVariantBase() {
    font-size: 12px;
    font-weight: 600;
    padding: 0 0.4rem;
    display: inline-flex;
    align-items: center;
    min-height: 22px;
    white-space: nowrap;
    border-radius: 8px;
}

.tagVariant(@base-color: @unable-color, @text-color: white) {
    .tagVariantBase();
    transition: background-color 0.15s ease;
    color: @text-color;
    border: 1px solid @base-color;
    background: @base-color;
    box-shadow: 0 5px 4px -2px rgba(@base-color, 0.4);
}

.tagOutlined(@base-color: black, @text-color: darken(@base-color, 10%)) {
    .tagVariantBase;
    color: @text-color;
    border: 1px solid @base-color;
    background: white;
}

.focus() {
    transition: none;
    border-radius: @border-radius-base;
    border-color: rgba(@primary-color, 0.65);
    box-shadow: 0 2px 4px 2px rgba(@primary-color, 0.1), 0 0 0 1px rgba(@primary-color, 0.65);
}

.cardFocus() {
    &:focus:not(:active) {
        .focus();
    }
}

.cardActive() {
    .focus();
    border-color: rgba(@primary-color, 0.65);
    background: rgba(@primary-color, 0.03, 0.005);
}

.tinyShadows(@hoverable: false, @focusable: false) {
    box-shadow: 0 1px 0 rgba(#3f5e75, 0.02), 0 1px 4px rgba(#3f5e75, 0.05);
    transition: box-shadow 0.2s, border-collor 0.2s;
    & when (@hoverable = true) {
        &:hover {
            border-color: white;
            box-shadow: 0 5px 15px rgba(124, 157, 191, 0.1), 0 9px 12px rgba(124, 157, 191, 0.1), 0 3px 6px -4px rgba(124, 157, 191, 0.15);
        }
    }
    & when (@focusable = true) {
        .cardFocus();
    }
}

.defaultShadows() {
    box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}

.card(@padding:1rem, @interactive: false) {
    .tinyShadows();
    padding: @padding;
    border-radius: @border-radius;
    background: @card-background;
    border: 1px solid @border-color-alt;
    & when (@interactive) {
        .tinyShadows(true, true);
    }
}

.cardBorderLess(@args...) {
    .card(@args);
    border: none;
}

.addTransition() {
    transition: @transition;
}

.gridCols(@min-width:320px) {
    grid-template-columns: repeat(auto-fill, minmax(@min-width, 1fr));
}

.gridCards(@min-width: 320px) {
    display: grid;
    grid-gap: 1rem;
    .gridCols(@min-width);
}

.size(@width; @height:@width) {
    width: @width;
    height: @height;
}

.widget(@padding:12px 16px) {
    .tinyShadows();
    padding: @padding;
    background-color: @background-alt;
    border-radius: @border-radius;
}

.widgetAlt(@padding:16px 24px) {
    .tinyShadows();
    padding: @padding;
    border-radius: @border-radius;
    background: @background-alt;
    border: 1px solid @border-color-alt;
}

.media-breakpoint(@maxWidth; @rules) {
    @media only screen and (max-width: @maxWidth + 0px) {
        @rules();
    }
}

.media-breakpoint-min(@minWidth; @rules) {
    @media only screen and (min-width: @minWidth + 0px) {
        @rules();
    }
}

.media-breakpoint-between(@minWidth; @maxWidth; @rules) {
    @media only screen and (min-width: @minWidth + 0px) and (max-width: @maxWidth + 0px) {
        @rules();
    }
}
