.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.85;
}

.discarded {
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.clickable {
    cursor: pointer;
}

.tiny-shadows {
    .tinyShadows();
}

.default-shadows {
    .defaultShadows();
}

.card {
    .card(1rem);
}

.flex-center {
    .flexCenter;
}

.justify-start {
    justify-content: flex-start;
}

.multiplier {
    font-weight: 600;
    font-style: normal;
    font-size: 0.7rem;
    border-radius: 4px;
    opacity: 0.5;
    padding: 0 1px;
    width: 35px;
    justify-content: center;
    align-self: center;
}

.meta {
    background: rgba(red, 0.1); // coz: meta is deprecated!!!
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
}

.opacity-5 {
    opacity: 0.5;
}

.space-between {
    display: flex;
    justify-content: space-between;
}
