// Got from https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin
/* open-sans-regular - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local(""),
    url("../../media/fonts/open-sans-v28-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    src: local(""),
    url("../../media/fonts/open-sans-v28-latin-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: local(""),
    url("../../media/fonts/open-sans-v28-latin-600.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local(""),
    url("../../media/fonts/open-sans-v28-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    src: local(""),
    url("../../media/fonts/open-sans-v28-latin-800.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
