div.ant-table {
    border-radius: 5px;
    background: #fff;
    border: 1px solid @border-color-base;
    overflow: auto;

    .ant-table {
        margin: 0 !important; // attn! expandable tables -> reset default margin
    }
}

.ant-table-body {
    overflow-x: auto !important;
}

.ant-table-tbody > tr:last-child > td {
    border-bottom: none;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    opacity: 0.5;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    opacity: 0.5;
}
