:root {
    --gray-color: @gray-color;
    --red-color: @red-color;
    --purple-color: @purple-color;
    --black-color: @black-color;
    --white-color: @white-color;
    --user-color: @user-color;
    --customer-color: @customer-color;
    --writer-color: @writer-color;
    --primary-color: @primary-color;
    --stripped-color: @stripped-color;
    --success-color: @success-color;
    --danger-color: @danger-color;
    --warning-color: @warning-color;
    --error-color: @error-color;
    --unable-color: @unable-color;
    --info-color: @info-color;
    --turquoise-color: @turquoise-color;
    --muted-color: @muted-color;
    --muted-text-color: @muted-text-color;
    --draft-color: @draft-color;
    --paused-color: @paused-color;
    --active-color: @active-color;
    --resolved-color: @resolved-color;
    --pending-color: @pending-color;
    --inwork-color: @inwork-color;
    --removed-color: @removed-color;
    --aborted-color: @aborted-color;
    --declined-color: @declined-color;
    --available-color: @available-color;
    --disabled-color: @disabled-color;
    --border-color: @border-color-alt;
    --link-color: @link-color;
    --text-color: @text-color;
    --heading-color: @heading-color;

    --header-height: @header-height;

    // BREAKPOINTS
    --xxs: @breakpoint-xxs;
    --xs: @breakpoint-xs;
    --sm: @breakpoint-sm;
    --md: @breakpoint-md;
    --lg: @breakpoint-lg;
    --xl: @breakpoint-xl;
    --xxl: @breakpoint-xxl;
}
